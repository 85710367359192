import React, { useEffect, useRef } from "react";
import {
  LocalActionWithQuantity_ReviewExecutionMobile,
  LocalMission_ReviewExecutionMobile,
  useReviewExecutionMobile,
} from "./useReviewExecutionMobile";
import { Input } from "../UiComponents/Input";
import { Button } from "../UiComponents/Button";
import dayjs from "dayjs";
import { resultToIcon } from "./ReviewExecutionMobile";
import { cn } from "../../helpers/utils";
import { Trash2 } from "lucide-react";
import { actions2Coded } from "../../helpers/actionLnrCoded";

type Props = {
  day: string;
  tourId: string;
  memberId: string;
};

type ActionSelect = {
  selected: boolean;
  action: LocalActionWithQuantity_ReviewExecutionMobile;
};

type TimeInput = {
  start: string;
  end: string;
  remark?: string;
  actions: ActionSelect[];
};

const EnterOneDay = (props: Props) => {
  const { day, tourId, memberId } = props;
  const {
    missionsMap,
    createExecutionMobile,
    missionActionCompareResultsMap,
    actionsMap,
  } = useReviewExecutionMobile();

  const [numberOfMissions, setNumberOfMissions] = React.useState(0);
  const [inputMap, setInputMap] = React.useState<Map<string, TimeInput>>(
    new Map()
  );

  const [missions, setMissions] = React.useState<
    LocalMission_ReviewExecutionMobile[]
  >([]);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  useEffect(() => {
    console.log("EnterOneDay: useEffect", day, tourId);
    if (!missionsMap) return;
    console.log();
    const _missions = missionsMap.get(day);
    console.log("Missions", _missions);
    setMissions(_missions || []);
    setNumberOfMissions(_missions?.length || 0);
  }, [missionsMap]);

  const parseInput = (input: string) => {
    return input.replace(/[^0-9]/g, "");
  };

  const parseTimeRange = (input: string, index: number): string => {
    // Remove any extra spaces from the input
    const sanitizedInput = input.trim();

    // Regular expression to match various formats
    const timeRangePattern = /(\d{2}):?(\d{2})[-,](\d{2}):?(\d{2})/;

    const match = sanitizedInput.match(timeRangePattern);

    if (!match) {
      // If the input doesn't match any valid pattern, return an empty string or throw an error
      return "";
    }

    // Extract the time components from the regex match
    const startHour = match[1];
    const startMinute = match[2];
    const endHour = match[3];
    const endMinute = match[4];

    const _inputMap = inputMap;

    _inputMap.set(missions[index].id, {
      start: startHour + ":" + startMinute,
      end: endHour + ":" + endMinute,
      actions:
        missions[index].actionsWithQuantity?.map((a) => ({
          selected: false,
          action: a,
        })) || [],
    });

    console.log("Input Map", _inputMap);

    setInputMap(new Map(_inputMap));

    // Return the formatted time range
    return `${startHour}:${startMinute}-${endHour}:${endMinute}`;
  };

  const handleParseInput = (index: number) => {
    const currentInput = inputRefs.current[index];
    if (currentInput) {
      // Parse the input and update the value
      currentInput.value = parseTimeRange(currentInput.value, index);
      //console.log("handleParseInput", index, currentInput.value);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault(); // Prevent form submission or default behavior
        handleParseInput(index); // Parse the input when Enter is pressed
        // Move focus to the next input
        if (inputRefs.current[index + 1]) {
          inputRefs.current[index + 1]?.focus();
        }
        break;
      case "ArrowUp":
        event.preventDefault(); // Prevent default scrolling behavior
        // Move focus to the previous input
        if (inputRefs.current[index - 1]) {
          inputRefs.current[index - 1]?.focus();
        }
        break;
      case "ArrowDown":
        event.preventDefault(); // Prevent default scrolling behavior
        // Move focus to the next input
        if (inputRefs.current[index + 1]) {
          inputRefs.current[index + 1]?.focus();
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="overflow-y-scroll">
      <div className="sticky top-0 bg-white p-2 flex flex-row justify-center gap-4 items-center">
        <div>{tourId}</div>
        <div>{day}</div>
        <div>{memberId}</div>

        <Button
          onClick={() => {
            inputMap.forEach((value, key) => {
              const mission = missions.find((m) => m.id === key);
              if (mission) {
                createExecutionMobile({
                  referenceId: mission.id,
                  startTS: day + "T" + value.start + ":00",
                  endTS: day + "T" + value.end + ":00",
                  codedActions:
                    actions2Coded(
                      value.actions.map((a) => {
                        return {
                          quantity: a.action?.quantity || 0,
                          lnr: a.action?.actionLnr || "0",
                        };
                      })
                    ) || "",
                  memmberId: memberId,
                  patientId: mission.patientId,
                  referenceType: "mission",
                  result: "success",
                  remark: value.remark || "",
                });
              }
            });
          }}
        >
          Sichern
        </Button>
      </div>
      {missions.map((m, index) => {
        const inputValue = inputMap.get(m.id);

        if (m.isActive === false) {
          return (
            <div className="line-through p-2 border-2 border-gray-200">
              <div>
                {m.patient?.lastName}, {m.patient?.firstName}
              </div>
              <div>{m.patientId}</div>
            </div>
          );
        }

        return (
          <div
            key={m.id}
            className="flex flex-row gap-2 p-2 border-2 border-gray-300 items-center"
          >
            <div>
              {m.patient?.lastName}, {m.patient?.firstName}
            </div>
            <div className="flex-flex-col text-xs text-gray-500">
              <div>{m.patientId}</div>
              <div>{dayjs(Number(m.startTS)).format("HH:mm")}</div>
              <div>{dayjs(Number(m.endTS)).format("HH:mm")}</div>
            </div>
            <Input
              className="w-40"
              type="text"
              ref={(el) => (inputRefs.current[index] = el)}
              onKeyDown={(event) => handleKeyDown(event, index)}
              onBlur={() => handleParseInput(index)}
            />
            <div className="flex flex-col w-full ">
              <div className="flex flex-row gap-2 w-full items-center">
                {inputValue ? (
                  <div>
                    <Trash2
                      size={18}
                      onClick={() => {
                        const _inputMap = inputMap;
                        _inputMap.delete(m.id);
                        setInputMap(new Map(_inputMap));
                      }}
                    />
                  </div>
                ) : null}
                <div>
                  {inputValue?.start}-{inputValue?.end}
                </div>
                <div className="flex flex-row gap-2">
                  {inputValue?.actions.map((a, index) => {
                    return (
                      <Button
                        variant={"neutral-ghost"}
                        key={index}
                        className={cn({
                          "bg-red-100": !a.selected,
                          "bg-green-100": a.selected,
                        })}
                        onClick={() => {
                          const _inputMap = inputMap;
                          const _inputValue = _inputMap.get(m.id);
                          if (_inputValue) {
                            _inputValue.actions[index].selected =
                              !_inputValue.actions[index].selected;
                            setInputMap(new Map(_inputMap));
                          }
                        }}
                      >
                        {a.action?.action?.leistungskomplex}
                      </Button>
                    );
                  })}
                </div>
                <input
                  className="w-full border-2 border-gray-300 p-1"
                  type="text"
                  placeholder="Bemerkung"
                  value={inputValue?.remark}
                  onChange={(e) => {
                    const _inputMap = inputMap;
                    const _inputValue = _inputMap.get(m.id);
                    if (_inputValue) {
                      _inputValue.remark = e.target.value;
                      setInputMap(new Map(_inputMap));
                    }
                  }}
                />
              </div>
              <div>
                {m.executionMobiles.map((em) => {
                  if (em.result !== "success") {
                    return null;
                  }

                  const _missionActionCompareResult =
                    missionActionCompareResultsMap?.get(m.id);

                  console.log(
                    "Mission Action Compare Result",
                    _missionActionCompareResult
                  );

                  return (
                    <div key={em.id} className="flex flex-row items-center">
                      <div className="flex flex-row gap-2 items-center">
                        <div>
                          <Trash2 size={18} />
                        </div>
                        <div>{resultToIcon(em.result)} </div>
                        <div
                          className={cn(
                            " flex felx-row gap-2 items-center",
                            {}
                          )}
                        >
                          <div className={cn({ "line-through": inputValue })}>
                            {dayjs(em.startTS_iso).format("HH:mm")}-
                            {dayjs(em.startTS_iso)
                              .add(em.duration_min, "minute")
                              .format("HH:mm")}
                          </div>
                          <div>{em.sourceDisplay}</div>
                          <div>
                            {_missionActionCompareResult && (
                              <div className="bg-white p-1 flex flex-row gap-2">
                                {_missionActionCompareResult.identical.map(
                                  (result) => {
                                    if (!result) return null;
                                    if (!result.lnr) return null;
                                    const action = actionsMap?.get(
                                      result.lnr.toString()
                                    );
                                    return (
                                      <div className="bg-green-100 p-1 text-xs">
                                        {action?.action?.leistungskomplex}
                                      </div>
                                    );
                                  }
                                )}
                                {_missionActionCompareResult.onlyMission.map(
                                  (result) => {
                                    if (!result) return null;
                                    if (!result.lnr) return null;
                                    const action = actionsMap?.get(
                                      result.lnr.toString()
                                    );

                                    return (
                                      <div className="bg-red-100 p-1 text-xs">
                                        {action?.action?.leistungskomplex}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                          <div>{em.remark}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EnterOneDay;
