import { create } from "zustand";
import {
  Missions_ReviewExecutionMobile,
  CreateOrUpdateExecutionMobile_ReviewExecutionMobile,
  DeleteExecutionMobile_ReviewExecutionMobile,
  SetValidationOneExecutionMobile_ReviewExecutionMobile,
  compareTuples,
  LocalActionWithQuantity_ReviewExecutionMobile,
  MissionActionCompareResult,
} from "../useReviewExecutionMobile";
import { LocalMission_ReviewExecutionMobile } from "../useReviewExecutionMobile";
import dayjs from "dayjs";
import { client } from "../../../urqlClient";
import _ from "lodash";
import { InputCreateExecutionMobile } from "../../../gql/graphql";
import toast from "react-hot-toast";

type UserInputEditOneDay = {
  inputStartTime?: string;
  inputEndTime?: string;
};

export type ActionExecution = {
  actionLnr: string;
  executionStatus: "executed" | "notExecuted" | "additionalExecuted";
  missionId: string;
  executionId: string;
  action: LocalActionWithQuantity_ReviewExecutionMobile;
};

interface ReviewOneDayState {
  selectedDay: string;
  selectedTourId: string;
  setSelectedDay: (day: string) => void;
  setSelectedTourId: (tourId: string) => void;
  allMissions: (LocalMission_ReviewExecutionMobile & UserInputEditOneDay)[];
  lastUpdated: string;
  missonExecutionResultMap: Map<string, ActionExecution[]> | undefined;
  missionActionCompareResultsMap:
    | Map<string, MissionActionCompareResult>
    | undefined;
  actionsMap:
    | Map<string, LocalActionWithQuantity_ReviewExecutionMobile>
    | undefined;
  loadData: (missionId?: string) => void;
  createOrUpdateExecutionMobile: (input: InputCreateExecutionMobile) => void;
  deleteExecutionMobile: ({
    id,
    missionId,
  }: {
    id: string;
    missionId: string;
  }) => void;
  setValidityOneExecutionMobile: ({
    id,
    valid,
    missionId,
  }: {
    id: string;
    valid: boolean;
    missionId: string;
  }) => void;
  updatingMissionIds: string[];
}
export const useReviewOneDay = create<ReviewOneDayState>((set, get) => ({
  selectedDay: "",
  selectedTourId: "",
  allMissions: [],
  updatingMissionIds: [],
  missionActionCompareResultsMap: undefined,
  missonExecutionResultMap: undefined,
  actionsMap: undefined,
  setSelectedDay: (day) => {
    set({
      selectedDay: day,
      allMissions: [],
      missonExecutionResultMap: undefined,
    });
    //get().loadData();
  },
  setSelectedTourId: (tourId) => {
    set({
      selectedTourId: tourId,
      allMissions: [],
      missonExecutionResultMap: undefined,
    });
    //get().loadData();
  },
  lastUpdated: (dayjs("2021-01-01").unix() * 1000).toString(),
  loadData: async (missionId?: string) => {
    console.log("loadData", dayjs(Number(get().lastUpdated)).toISOString());
    const res = await client
      .query(
        Missions_ReviewExecutionMobile,
        {
          filter: {
            maxDate: get().selectedDay,
            minDate: get().selectedDay,
            tourIds: [get().selectedTourId],
            updatedLater: get().lastUpdated,
          },
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise();
    console.log(res);

    if (missionId) {
      set({
        updatingMissionIds: get().updatingMissionIds.filter(
          (id) => id !== missionId
        ),
      });
    }

    set({
      allMissions: _.orderBy(
        res?.data?.missionsTimeRange || [],
        ["startTS"],
        ["asc"]
      ),
      //lastUpdated: (dayjs().unix() * 1000).toString(),
    });

    const _actionsMap = new Map<
      string,
      LocalActionWithQuantity_ReviewExecutionMobile
    >();

    const missions = get().allMissions;

    const _districtActions = _.chain(missions)
      .map((m) => m.actionsWithQuantity)
      .flatten()
      .uniqBy("actionLnr")
      .value();

    for (let action of _districtActions) {
      if (!action) continue;
      _actionsMap.set(action.actionLnr, action);
    }

    set({ actionsMap: _actionsMap });

    const _missionCompareResultsMap = new Map<
      string,
      MissionActionCompareResult
    >();

    const _missionExecutionsMap = new Map<string, ActionExecution[]>();

    for (let mission of missions) {
      if (!mission.executionMobiles) continue;
      if (!mission.actionsWithQuantity) continue;

      for (let execution of mission.executionMobiles) {
        if (!execution.actionsWithQuantity) continue;
        if (!mission.actionsWithQuantity) continue;
        if (execution.result !== "success") continue;

        const _actionExecutions: ActionExecution[] = [];

        const missionActions = mission.actionsWithQuantity;
        const executionActions = execution.actionsWithQuantity;

        for (let a of missionActions) {
          if (!a) continue;
          // check if action is in execution
          const _action = executionActions.find(
            (e) => e.actionLnr === a?.actionLnr
          );

          if (_action) {
            _actionExecutions.push({
              actionLnr: a.actionLnr,
              executionStatus: "executed",
              missionId: mission.id,
              executionId: execution.id,
              action: a,
            });
          } else {
            _actionExecutions.push({
              actionLnr: a.actionLnr,
              executionStatus: "notExecuted",
              missionId: mission.id,
              executionId: execution.id,
              action: a,
            });
          }
        }
        _missionExecutionsMap.set(execution.id, _actionExecutions);
      }
    }

    console.log("missionExecutionsMap", _missionExecutionsMap);
    set({ missionActionCompareResultsMap: _missionCompareResultsMap });
    set({ missonExecutionResultMap: _missionExecutionsMap });
  },
  createOrUpdateExecutionMobile: async (input: InputCreateExecutionMobile) => {
    console.log("createOrUpdateExecutionMobile", input);
    const missionId = input.referenceId;

    set({
      updatingMissionIds: [...get().updatingMissionIds, missionId],
    });

    client
      .mutation(CreateOrUpdateExecutionMobile_ReviewExecutionMobile, {
        data: input,
      })
      .toPromise()
      .then(() => {
        get().loadData(missionId);
      })
      .catch((e) => {
        toast.error("Error: " + e.message);
        get().loadData(missionId);
      });
  },

  deleteExecutionMobile: async ({ id, missionId }) => {
    console.log("deleteExecutionMobile", id);

    set({
      updatingMissionIds: [...get().updatingMissionIds, missionId],
    });

    // find the mission whre execution belongs to

    client
      .mutation(DeleteExecutionMobile_ReviewExecutionMobile, {
        id: id,
      })
      .toPromise()
      .then(() => {
        get().loadData(missionId);
      })
      .catch((e) => {
        toast.error("Error: " + e.message);
        get().loadData(missionId);
      });
  },
  setValidityOneExecutionMobile: async ({ id, valid, missionId }) => {
    console.log("setValidOneExecutionMobile", id, valid);

    set({
      updatingMissionIds: [...get().updatingMissionIds, missionId],
    });

    client
      .mutation(SetValidationOneExecutionMobile_ReviewExecutionMobile, {
        id: id,
        isInvalid: valid,
      })
      .toPromise()
      .then(() => {
        get().loadData(missionId);
      })
      .catch((e) => {
        toast.error("Error: " + e.message);
        get().loadData(missionId);
      });
  },
}));
