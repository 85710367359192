import { TourStatQuery } from "@/src/gql/graphql";
import { graphql } from "../../gql";
import React, { useEffect } from "react";
import { client } from "../../../src/urqlClient";

const ExecutionTourStatData = graphql(/* GraphQL */ `
  query TourStat($day: String!, $tourId: String!) {
    executionMobileTourStat(day: $day, tourId: $tourId) {
      tourId
      day
      countMissions
      countFailedExecutions
      countSuccessExecutions
      ratioDurationPlan_Execution
      totalExecutionDurationSuccess
      totalMissionDurationPlan
      countMissionsNoExecutions
    }
  }
`);

type TourStat = TourStatQuery["executionMobileTourStat"];

type Props = {
  tourId: string;
  day: string;
};

const ExecutionTourStat = (props: Props) => {
  const { tourId, day } = props;
  const [tourStat, setTourStat] = React.useState<TourStat>(null);

  useEffect(() => {
    client
      .query(ExecutionTourStatData, { tourId, day })
      .toPromise()
      .then((result) => {
        console.log(result);
        setTourStat(result?.data?.executionMobileTourStat);
      });
  }, [tourId, day]);

  return (
    <div className="flex flex-col">
      <div>V: {tourStat?.ratioDurationPlan_Execution}</div>
      <div>Failed: {tourStat?.countFailedExecutions}</div>
      <div>No Execution: {tourStat?.countMissionsNoExecutions}</div>
    </div>
  );
};

export default ExecutionTourStat;
