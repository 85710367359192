import React, { useEffect } from "react";

import { useReviewExecutionMobile } from "./useReviewExecutionMobile";
import dayjs from "dayjs";
import { actionLnrsCoded2Object } from "../../helpers/actionLnrCoded";
import DisplayAction from "../Action/DisplayAction";
import { LocalActionWithQuantity } from "@/src/type-definitions";

type Props = {};

const AllMissionsWithComments = (props: Props) => {
  const { loadAllMissionsWithComments, allMissions } =
    useReviewExecutionMobile();

  useEffect(() => {
    loadAllMissionsWithComments();
  }, []);

  return (
    <div className="h-full overflow-y-scroll">
      {allMissions.map((mission) => {
        return (
          <div
            key={mission.id}
            className="flex flex-row gap-2 p-1 items-center"
          >
            <div className="w-20 text-xs">
              {dayjs(mission.day).format("ddd, DD. MMM")} {mission.time}
            </div>
            <div>{mission.member?.shortName}</div>
            <div className="bg-gray-100 p-2">
              {mission.patient?.lastName} {mission.patient?.firstName}
            </div>
            <div>SOLL:{mission.duration_min}</div>
            {/* <div className="text-xs flex flex-row">
              {mission.actionsWithQuantity?.map((awq) => {
                return (
                  <DisplayAction
                    key={awq?.actionLnr}
                    action={
                      {
                        ...awq,
                      } as LocalActionWithQuantity
                    }
                  />
                );
              })}
            </div> */}

            <div>
              {mission.executionMobiles.map((em) => {
                return (
                  <div key={em.id} className="flex flex-row items-center">
                    <div>IST: {em.duration_min}</div>

                    <div className="bg-gray-50 p-1">{em.remark}</div>
                    {/* <div className="text-xs flex flex-row">
                      {em.actionsWithQuantity?.map((awq) => {
                        return (
                          <DisplayAction key={awq?.actionLnr} action={awq} />
                        );
                      })}
                    </div> */}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AllMissionsWithComments;
