import React, { useCallback, useEffect } from "react";
import inMemoryJwt from "../Auth0/inMemoryJwt";
import LoginButton from "../Auth0/LoginButton";
import { useReviewExecutionMobile } from "./useReviewExecutionMobile";
import dayjs from "dayjs";
import { Dialog, DialogTrigger } from "../UiComponents/Dialog";
import { Button } from "../UiComponents/Button";
import { DialogContent } from "../UiComponents/Dialog";
import {
  CircleHelp,
  Edit2Icon,
  Frown,
  Hand,
  InfoIcon,
  MessageSquare,
  SquareCheckBig,
  TriangleAlert,
} from "lucide-react";
import { House } from "lucide-react";
import EditExecutionMobile from "./EditExecutionMobile";
import { ChatBubbleIcon } from "@radix-ui/react-icons";
import { getEndDateOfWeek, getStartDateOfWeek } from "../../helpers/datetime";
import SelectWeekTourId from "./SelectWeekTourId";
import _ from "lodash";
import AllMissionsWithComments from "./AllMissionsWithComments";
import DisplayMissionAnalytic from "../DisplayMissionAnalytic/DisplayMissionAnalytic";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../UiComponents/Popover";
import AllReviewExecutionMobile from "./AllReviewExecutionMobile";
import { cn } from "../../helpers/utils";
import { Link } from "react-router-dom";
import DisplayPatientIdWithLink from "../Patient/DisplayPatientIdWithLink/DisplayPatientIdWithLink";
import toast from "react-hot-toast";
import usePageVisibility from "../../helpers/usePageVisibilty";
import EnterOneDay from "./EnterOneDay";
import ExecutionTourStat from "./ExecutionTourStat";

const DisplayTimeDiffence = (props: {
  durationPlanned: number;
  durationExecuted: number;
}) => {
  const { durationPlanned, durationExecuted } = props;

  console.log(durationPlanned, durationExecuted);

  if (durationExecuted === 0)
    return (
      <div className="p-1 bg-yellow-400">
        <TriangleAlert className=" text-black " size={17} />
      </div>
    );

  if (durationPlanned === 0) {
    return (
      <div className="p-1 bg-red-400">
        <TriangleAlert className=" text-black " size={17} />
      </div>
    );
  }

  if (
    (durationExecuted / durationPlanned > 1.1 &&
      durationExecuted - durationPlanned < 3) ||
    (durationExecuted / durationPlanned < 0.7 &&
      durationExecuted - durationPlanned < -2)
  ) {
    return (
      <div className="p-1 bg-red-500 text-white">
        <div>{durationExecuted - durationPlanned}</div>
      </div>
    );
  }

  return (
    <div className="p-1">
      <div>{durationExecuted - durationPlanned}</div>
    </div>
  );
};

type Props = {};

export const ReviewExecutionMobile = (props: Props) => {
  const token = inMemoryJwt.getToken();

  if (token === null) {
    return (
      <div>
        Token holen
        <LoginButton />
      </div>
    );
  }

  return (
    <>
      {/* <AllReviewExecutionMobile /> */}
      <ReviewExecutionMobile1 />
    </>
  );
};

export const resultToIcon = (result: string) => {
  switch (result) {
    case "success":
      return (
        <SquareCheckBig className="bg-gray-100 text-green-500" size={17} />
      );
    case "no-open":
      return <House size={17} className="bg-gray-100 text-red-500" />;
    case "no-allow":
      return <Hand size={17} className="bg-gray-100 text-red-500" />;
    case "not-possible":
      return <Frown size={17} className="bg-gray-100 text-red-500" />;
    default:
      return <CircleHelp size={17} className="bg-gray-100 text-red-500" />;
  }
};

const ReviewExecutionMobile1 = (props: Props) => {
  const {
    loadData,
    distinctDays,
    missionsMap,
    setSelectedMission,
    selectedMemberId,
    selectedTourId,
    startDay,
    endDay,
    analyzeTour,
    loadTours,
    loadAllMissionsWithComments,
    missionActionCompareResultsMap,
    actionsMap,
  } = useReviewExecutionMobile();

  const [openEditExecutionMobile, setOpenEditExecutionMobile] =
    React.useState(false);

  const _deferredLoadData = useCallback(_.debounce(loadData, 700), []);

  const handleTabVisible = () => {
    console.log("Tab is visible");
    // Your custom function logic here
    toast.success("Wilkommen zurück!", {
      position: "top-center",
    });
    _deferredLoadData();
  };

  //sePageVisibility(handleTabVisible);

  return (
    <div className="bg-white">
      <div className="flex flex-row gap-2 sticky top-0 bg-white p-2">
        <Button
          onClick={() => {
            loadData();
          }}
        >
          Neu Laden
        </Button>
        <Dialog>
          <DialogTrigger>
            <Button>All With Comments</Button>
          </DialogTrigger>
          <DialogContent className="w-[80%] h-[80%] overflow-y-hidden">
            <AllMissionsWithComments />
          </DialogContent>
        </Dialog>
        <Dialog>
          <DialogTrigger>
            <Button>Tour auswählen</Button>
          </DialogTrigger>
          <DialogContent className="w-[80%] h-[80%] overflow-y-scroll">
            <SelectWeekTourId />
          </DialogContent>
        </Dialog>
        <div className="font-semibold">
          {dayjs(startDay).format("ddd, DD.MMM")} -{" "}
          {dayjs(endDay).format("ddd, DD.MMM")}
        </div>
        <div className="font-semibold">{selectedTourId}</div>
      </div>
      <div className="flex flex-row gap-2">
        {distinctDays.map((day) => {
          const _missions = missionsMap?.get(day);

          const _distinctMembers = _.chain(_missions)
            .uniqBy("memberId")
            .value();

          if (_distinctMembers.length !== 1)
            return <div>Nicht eindeutige Mitarbeier</div>;

          const _distinctMember = _distinctMembers[0].member;

          return (
            <div className="flex flex-col gap-2 w-64 bg-gray-100 p-2" key={day}>
              <div className="text-white bg-gray-400 p-1 flex flex-row justify-between sticky top-10">
                <div className="flex flex-col">
                  <div className="flex flex-row gap-2">
                    <div>{dayjs(day).format("ddd, DD.MMMM")}</div>
                    <div>{_distinctMember?.shortName}</div>
                    <Dialog>
                      <DialogTrigger>
                        <Button>E</Button>
                      </DialogTrigger>
                      <DialogContent className="h-[80%] w-[80%]">
                        <EnterOneDay
                          day={day}
                          tourId={selectedTourId}
                          memberId={_distinctMember?.memberId || ""}
                        />
                      </DialogContent>
                    </Dialog>
                  </div>
                  <div>
                    <ExecutionTourStat day={day} tourId={selectedTourId} />
                  </div>
                </div>
              </div>

              {missionsMap?.get(day)?.map((mission) => {
                const hasMobileExecWithComment = mission.executionMobiles.some(
                  (executionMobile) => executionMobile.remark !== ""
                );

                const _missionActionCompareResult =
                  missionActionCompareResultsMap?.get(mission.id);

                const successExecutions = mission.executionMobiles.filter(
                  (executionMobile) => executionMobile.result === "success"
                );

                const sucessDuration = successExecutions.reduce(
                  (acc, executionMobile) => acc + executionMobile.duration_min,
                  0
                );

                return (
                  <div className="flex flex-col" key={mission.id}>
                    <div className="flex fle-row justify-between items-center">
                      <div className=" flex flex-row gap-2 items-center">
                        <div
                          className={cn("font-semibold", {
                            "bg-red-300": !mission.isActive,
                          })}
                        >
                          {mission.patient?.shortName}
                        </div>
                        <DisplayPatientIdWithLink
                          className="text-xs"
                          patientId={mission.patientId}
                        />
                      </div>
                      {/* <div className="flex flex-row gap-1 justify-between items-center p-1"></div> */}
                      <div className="flex flex-row justify-end items-center">
                        <div>
                          <Popover>
                            <PopoverTrigger>
                              <Button variant={"neutral-ghost"}>
                                <InfoIcon className="text-blue-500" size={15} />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent className="text-xs">
                              <DisplayMissionAnalytic missionId={mission.id} />
                            </PopoverContent>
                          </Popover>
                        </div>

                        <div>
                          {hasMobileExecWithComment && (
                            <div>
                              <MessageSquare size={15} />
                            </div>
                          )}
                        </div>
                        <Button
                          variant={"neutral-ghost"}
                          onClick={() => {
                            setSelectedMission(mission);
                            setOpenEditExecutionMobile(true);
                          }}
                        >
                          <Edit2Icon size={15} />
                        </Button>
                      </div>
                    </div>
                    <div className="flex flex-row gap-2 p-1">
                      <div className="flex flex-col gap-2 w-1/2 bg-white">
                        <div className="flex flex-row justify-between items-center">
                          <div className="text-xs">
                            {mission.time} ({mission.duration_min})
                          </div>

                          <div>
                            <DisplayTimeDiffence
                              durationExecuted={sucessDuration}
                              durationPlanned={mission.duration_min}
                            />
                          </div>
                        </div>
                        {/* <div className="flex  flex-row gap-1">
                          {mission.actionsWithQuantity?.map((awq) => {
                            return <div>{awq?.actionLnr}</div>;
                          })}
                        </div> */}
                      </div>

                      <div className="flex flex-col gap-2 bg-gray-100 w-1/2">
                        {mission.executionMobiles.map((executionMobile) => {
                          if (executionMobile.isInvalid) return null;
                          return (
                            <div
                              key={executionMobile.id}
                              className="flex flex-col"
                            >
                              <div className="w-full flex flex-row justify-between bg-white p-1 gap-1 items-center">
                                <div>
                                  {resultToIcon(executionMobile.result)}
                                </div>
                                <div>
                                  {dayjs(executionMobile.startTS_iso).format(
                                    "HH:mm"
                                  )}
                                </div>
                                <div>{executionMobile.duration_min}</div>
                              </div>
                              {/* <div>{executionMobile.codedActions}</div> */}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {_missionActionCompareResult && (
                      <div className="bg-white p-1 flex flex-row gap-2">
                        {_missionActionCompareResult.identical.map((result) => {
                          if (!result) return null;
                          if (!result.lnr) return null;
                          const action = actionsMap?.get(result.lnr.toString());
                          return (
                            <div className="bg-green-100 p-1 text-xs">
                              {action?.action?.leistungskomplex}
                            </div>
                          );
                        })}
                        {_missionActionCompareResult.onlyMission.map(
                          (result) => {
                            if (!result) return null;
                            if (!result.lnr) return null;
                            const action = actionsMap?.get(
                              result.lnr.toString()
                            );

                            return (
                              <div className="bg-red-100 p-1 text-xs">
                                {action?.action?.leistungskomplex}
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <Dialog
        open={openEditExecutionMobile}
        onOpenChange={setOpenEditExecutionMobile}
      >
        <DialogContent className="w-[60%] h-[80%]">
          <EditExecutionMobile />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ReviewExecutionMobile;
