import React, { useEffect } from "react";

import { useReviewExecutionMobile } from "./useReviewExecutionMobile";
import _, { get } from "lodash";
import { getEndDateOfWeek, getStartDateOfWeek } from "../../helpers/datetime";
import dayjs from "dayjs";
import { to } from "@react-spring/web";
import { Button } from "../UiComponents/Button";
import { DialogClose } from "../UiComponents/Dialog";

type Props = {};

const SelectWeekTourId = (props: Props) => {
  const { loadTours, allTours, distinctMembers, setStartEndDayTourId } =
    useReviewExecutionMobile();
  const [startDay, setStartDay] = React.useState(
    dayjs(getStartDateOfWeek(dayjs().format("YYYY-MM-DD"))).format("YYYY-MM-DD")
  );
  const [endDay, setEndDay] = React.useState(
    dayjs(getEndDateOfWeek(dayjs().format("YYYY-MM-DD"))).format("YYYY-MM-DD")
  );

  const [allDays, setAllDays] = React.useState<string[]>([]);

  useEffect(() => {
    loadTours({
      startDay: startDay,
      endDay: endDay,
    });
  }, [startDay, endDay]);

  return (
    <div>
      <div>
        <Button
          onClick={() => {
            setStartDay(
              dayjs(startDay).subtract(7, "days").format("YYYY-MM-DD")
            );
            setEndDay(dayjs(endDay).subtract(7, "days").format("YYYY-MM-DD"));
          }}
        >
          -
        </Button>
        {startDay} {endDay}
        <Button
          onClick={() => {
            setStartDay(dayjs(startDay).add(7, "days").format("YYYY-MM-DD"));
            setEndDay(dayjs(endDay).add(7, "days").format("YYYY-MM-DD"));
          }}
        >
          +
        </Button>
      </div>

      <div className="flex flex-col gap-2">
        {distinctMembers?.map((member) => {
          const tours = _.filter(allTours, (tour) => {
            return tour?.member?.memberId === member?.memberId;
          });

          const distinctTourIds = _.uniqBy(tours, "tourId").map((tour) => {
            return tour?.tourId;
          });
          const distinctDays = _.uniqBy(tours, "day").map((tour) => {
            return tour?.date;
          });

          console.log(distinctDays);
          console.log(distinctTourIds);

          return (
            <div className="flex flex-col">
              <div className="flex flex-row gap-2">
                {member?.lastName}, {member?.firstName}
                {distinctTourIds.map((tourId) => {
                  return (
                    <div className="flex flex-row gap-2" key={tourId}>
                      <DialogClose>
                        <Button
                          onClick={() => {
                            setStartEndDayTourId({
                              startDay: startDay,
                              endDay: endDay,
                              tourId: tourId || "",
                            });
                          }}
                        >
                          {tourId}
                        </Button>
                      </DialogClose>

                      {/* {distinctDays.map((day) => {
                        if (!day) return null;
                        return (
                          <div className="flex flex-row gap-2" key={day}>
                            {dayjs(day).format("ddd, DD.MM.YYYY")}
                          </div>
                        );
                      })} */}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectWeekTourId;
