import { Dialog } from "@radix-ui/react-dialog";
import { shouldAutoRemoveFilter } from "@tanstack/react-table";
import React, { useEffect } from "react";
import { LocalActionWithQuantity } from "../../type-definitions";
import DisplayAction from "../Action/DisplayAction";
import { Button } from "../UiComponents/Button";
import { DialogClose, DialogContent } from "../UiComponents/Dialog";

import { useActionClipboard } from "./useActionClipboard";
import { BiTrash } from "react-icons/bi";

type Props = {};

const EditActionClipboard = (props: Props) => {
  const {
    actionsClipboardContent,
    selectedActions,
    allActions,
    sonderActions,

    grundPflegeActions,
    filteredBehandlungPflegeActions,
    timedActions,
    loadAllActions,
    addOneToAction,
    subOneToAction,
    filter,
    setFilter,
    clearActionClipboardContent,
  } = useActionClipboard();

  useEffect(() => {
    console.log("useffect");
    loadAllActions();
    setFilter("");
  }, [loadAllActions, setFilter]);

  return (
    <div className="bg-white flex flex-col h-[90%] w-full">
      <div className="flex flex-row items-center">
        <div className="flex flex-wrap text-xs h-24 bg-gray-200 mb-2 p-2 w-5/6 overflow-auto">
          {actionsClipboardContent.map((action) => {
            return (
              <div className="flex flex-row justify-center items-baseline">
                <div className="flex flex-row ">
                  <DisplayAction key={action.action.lnr} action={action} />
                  <span className="">{action.action.leistungskomplex}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col h-24 w-1/6 m-2">
          <input
            className="bg-gray-50 w-full p-2 m-2"
            placeholder="Suchen"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <div className="m-2">
            <BiTrash
              onClick={() => {
                clearActionClipboardContent();
              }}
              className="h-5 w-5"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between items-start bg-gray-50 p-2 h-5/6">
        <div className="flex flex-col w-1/4 m-2 overflow-y-auto h-full">
          <div className="bg-gray-200 flex ">Grund</div>

          {grundPflegeActions.map((action) => {
            const aq = {
              quantity: 1,
              actionLnr: action?.lnr,
              action: action,
            };
            return (
              <div className="flex flex-row justify-start items-baseline  text-xs ">
                <span className="flex flex-row">
                  <DisplayAction
                    key={action?.lnr}
                    action={aq as LocalActionWithQuantity}
                  />
                  <span>{action?.leistungskomplex}</span>
                </span>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col overflow-y-auto w-1/4 m-2 h-full">
          <div className="flex bg-gray-200">Behandlung</div>
          {filteredBehandlungPflegeActions.map((action) => {
            const aq = {
              quantity: 1,
              actionLnr: action?.lnr,
              action: action,
            };
            return (
              <div className="flex flex-row justify-start items-baseline text-xs">
                <div className="flex flex-row">
                  <DisplayAction
                    key={action?.lnr}
                    action={aq as LocalActionWithQuantity}
                  />
                  <span>{action?.leistungskomplex}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col w-1/4 m-2 overflow-y-auto">
          <div className="flex bg-gray-200 ">Zeit</div>
          {timedActions.map((action) => {
            const aq = {
              quantity: 1,
              actionLnr: action?.lnr,
              action: action,
            };
            return (
              <div className="flex flex-row justify-start items-center text-xs">
                <Button
                  variant="neutral-ghost"
                  onClick={() => subOneToAction(aq)}
                >
                  -
                </Button>
                <div className="flex flex-row">
                  <DisplayAction
                    key={action?.lnr}
                    action={aq as LocalActionWithQuantity}
                  />
                  <span>{action?.leistungskomplex}</span>
                </div>
                <Button
                  variant="neutral-ghost"
                  onClick={() => addOneToAction(aq)}
                >
                  +
                </Button>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col w-1/4 m-2">
          <div className="flex bg-gray-200">Zusatz</div>
          {sonderActions.map((action) => {
            const aq = {
              quantity: 1,
              actionLnr: action?.lnr,
              action: action,
            };
            return (
              <div className="flex flex-row justify-start items-baseline text-xs">
                <div className="flex flex-row">
                  <DisplayAction
                    key={action?.lnr}
                    action={aq as LocalActionWithQuantity}
                  />
                  <span>{action?.leistungskomplex}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EditActionClipboard;
