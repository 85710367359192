import React from "react";

import {
  LocalExecutionMobile_ReviewExecutionMobile,
  useReviewExecutionMobile,
} from "./useReviewExecutionMobile";

import dayjs from "dayjs";
import { Button } from "../UiComponents/Button";
import { Input } from "../UiComponents/Input";

import { RadioGroup, RadioGroupItem } from "../UiComponents/Radiogroup";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../UiComponents/Form";
import { start } from "repl";
import { create, set } from "lodash";
import { createId } from "@paralleldrive/cuid2";
import { cn } from "../..//helpers/utils";
import { Switch } from "../UiComponents/Switch";
import { Trash2Icon } from "lucide-react";
import { Textarea } from "../UiComponents/Textarea";

type Props = {};

const mobileExecutionResults = [
  {
    value: "success",
    label: "Erfolgreich",
  },
  {
    value: "no-open",
    label: "Nicht geöffnet",
  },
  {
    value: "no-allow",
    label: "Verweigert",
  },
];

const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

const FormSchema = z
  .object({
    result: z.enum(["success", "no-open", "no-allow"]),
    start: z.string().regex(timeFormatRegex, { message: "Zeitformat: HH:mm" }),
    end: z.string().regex(timeFormatRegex, { message: "Zeitformat: HH:mm" }),
    remark: z.string().max(300).optional(),
  })
  .refine(
    (data) => {
      const [startHour, startMinute] = data.start.split(":").map(Number);
      const [endHour, endMinute] = data.end.split(":").map(Number);
      const startTotalMinutes = startHour * 60 + startMinute;
      const endTotalMinutes = endHour * 60 + endMinute;
      return endTotalMinutes > startTotalMinutes;
    },
    {
      message: "End muss nach Start sein",
      path: ["endTime"],
    }
  );

const EditExecutionMobile = (props: Props) => {
  const {
    selectedMission,
    writeData,
    setNextSelectedMission,
    setPrevSelectedMission,
    preMissionSelected,
  } = useReviewExecutionMobile();

  const [executionMobiles, setExecutionMobiles] = React.useState<
    LocalExecutionMobile_ReviewExecutionMobile[]
  >([]);

  const [loadedExecutionMobiles, setLoadedExecutionMobiles] = React.useState<
    LocalExecutionMobile_ReviewExecutionMobile[]
  >([]);

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    const startTS = dayjs(selectedMission?.day + " " + data.start);
    const endTS = dayjs(selectedMission?.day + " " + data.end);

    const _newExecutionMobile: LocalExecutionMobile_ReviewExecutionMobile = {
      id: createId(),
      startTS_iso: startTS.toISOString(),
      endTS_iso: endTS.toISOString(),
      result: data.result,
      duration_min: endTS.diff(startTS, "minute"),
      codedActions: "",
      isInvalid: false,
      source: "manual-edit",
      sourceDisplay: "MA-Zettel",
      remark: data.remark,
    };

    setExecutionMobiles([...executionMobiles, _newExecutionMobile]);

    console.log(data);
  };

  const deleteExecutionMobile = (id: string) => {
    const newExecutionMobiles = executionMobiles.filter(
      (executionMobile) => executionMobile.id !== id
    );

    setExecutionMobiles(newExecutionMobiles);
  };

  const toggleValidExecutionMobile = (id: string) => {
    const newExecutionMobiles = executionMobiles.map((executionMobile) => {
      if (executionMobile.id === id) {
        return {
          ...executionMobile,
          isInvalid: !executionMobile.isInvalid,
        };
      }

      return executionMobile;
    });

    setExecutionMobiles(newExecutionMobiles);
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  React.useEffect(() => {
    setExecutionMobiles(selectedMission?.executionMobiles || []);
    setLoadedExecutionMobiles(selectedMission?.executionMobiles || []);
  }, [selectedMission]);

  if (!selectedMission) return <div>Kein Einsatz markiert</div>;

  return (
    <div>
      <div className="text-xs text-gray-400">{selectedMission?.id}</div>
      <div className="flex flex-row gap-2">
        {dayjs(selectedMission?.day).format("ddd, DD.MM")}{" "}
        <div className="font-semibold">{selectedMission?.time}</div>(
        {selectedMission?.duration_min})
        <div>
          <Button
            onClick={() => {
              setPrevSelectedMission();
            }}
          >
            Vorher
          </Button>
          <Button
            onClick={() => {
              setNextSelectedMission();
            }}
          >
            Nächster
          </Button>
        </div>
      </div>

      <div>
        {selectedMission?.patient?.lastName}{" "}
        {selectedMission?.patient?.firstName}{" "}
      </div>
      <div>
        {executionMobiles?.map((executionMobile) => {
          return (
            <div
              key={executionMobile.id}
              className={cn("flex flex-row gap-2", {
                "bg-red-100": executionMobile.isInvalid,
              })}
            >
              <div className="w-12">
                {executionMobile.source === "mobile-app" ? (
                  <div>
                    <Button
                      variant={"neutral-ghost"}
                      onClick={() => {
                        toggleValidExecutionMobile(executionMobile.id);
                      }}
                    >
                      <Switch
                        checked={!executionMobile.isInvalid}
                        onChange={() => {}}
                      />
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button
                      onClick={() => {
                        deleteExecutionMobile(executionMobile.id);
                      }}
                      variant={"danger-ghost"}
                      size={"compact-sm"}
                    >
                      <Trash2Icon className="w-6 h-6" />
                    </Button>
                  </div>
                )}
              </div>
              <div>{executionMobile.source}</div>
              <div>{dayjs(executionMobile.startTS_iso).format("HH:mm")}</div>
              <div>{dayjs(executionMobile.endTS_iso).format("HH:mm")}</div>
              <div>{executionMobile.result}</div>
              <div>{executionMobile.duration_min}</div>
              <div>{executionMobile.codedActions}</div>
              <div>{executionMobile.remark}</div>
            </div>
          );
        })}
      </div>
      <Button
        onClick={() => {
          writeData({
            originalMobileExecutions: loadedExecutionMobiles,
            updatedMobileExecutions: executionMobiles,
          });
        }}
      >
        save
      </Button>
      <Button
        onClick={() => {
          writeData({
            originalMobileExecutions: loadedExecutionMobiles,
            updatedMobileExecutions: executionMobiles,
          });

          setNextSelectedMission();
        }}
      >
        save + next
      </Button>
      <div className="w-96 border-2 border-black p-4">
        <Form {...form}>
          <Button
            onClick={() => {
              form.setValue("result", "success");
              form.setValue(
                "start",
                dayjs(Number(selectedMission.startTS)).format("HH:mm")
              );
              form.setValue(
                "end",
                dayjs(Number(selectedMission.endTS)).format("HH:mm")
              );
            }}
          >
            Aus Einsatz
          </Button>
          <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-row gap-2 p-2">
              <FormField
                control={form.control}
                name="start"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center">
                    <FormLabel className="w-24">Start Zeit</FormLabel>
                    <FormControl>
                      <Input placeholder="start" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="end"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center">
                    <FormLabel className="w-24">End Zeit</FormLabel>
                    <FormControl>
                      <Input placeholder="End Zeit" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="result"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-row space-y-1"
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="success" />
                        </FormControl>
                        <FormLabel className="font-normal">
                          Erfolgreich
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="no-open" />
                        </FormControl>
                        <FormLabel className="font-normal">
                          Öffnet Nicht
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="no-allow" />
                        </FormControl>
                        <FormLabel className="font-normal">
                          Verweigert
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="remark"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel className="w-24">Bemerkung</FormLabel>
                  <FormControl>
                    <Textarea placeholder="Bemerkung" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button disabled={!form.formState.isValid} type="submit">
              Anlegen
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default EditExecutionMobile;
