import React, { useEffect } from "react";

import { MissionPause, useMissionEasyPlan } from "./useMissionEasyPlan";
import { DisplayPatientInfo } from "../Patient/DisplayPatientInfo";
import PatientMap from "../Patient/PatientMap/PatientMap";
import ActionClipboard from "../ActionClipboard/ActionClipboard";
import { useActionClipboard } from "../ActionClipboard/useActionClipboard";
import DisplayAction from "../Action/DisplayAction";
import { Button } from "../UiComponents/Button";
import { Label } from "../UiComponents/Label";
import { Input } from "../UiComponents/Input";

type Props = {
  missionId: string;
  tourId: string;
  type: string;
};

const selectableMinutes = ["05", "10", "15", "20", "25", "30"];

const EditMission = (props: Props) => {
  const { missionId, type, tourId } = props;

  const {
    getMissionById,
    setMissionActions,
    splitMission,
    missionsMap,
    randomValue,
    setMissionDurationAction,
    countChangedMissions,
  } = useMissionEasyPlan();

  const { actionsClipboardContent } = useActionClipboard();

  const [mission, setMission] = React.useState<MissionPause | undefined>(
    getMissionById(missionId)
  );

  const [duration, setDuration] = React.useState<string>("05");

  const [myRandom, setMyRandom] = React.useState(0);

  React.useEffect(() => {
    console.log("useEffect");
    const _mission = getMissionById(missionId);
    setMission(_mission);
    setMyRandom(randomValue);
    setDuration(_mission?.duration_min.toString() || "05");
  }, [missionId, missionsMap?.get(tourId), tourId, randomValue]);

  return (
    <div className="flex flex-col">
      <div>
        <div className="">
          <ActionClipboard />
          <Label htmlFor="duration">
            Dauer (
            {selectableMinutes.map((m) => {
              return (
                <span>
                  <Button
                    onClick={() => {
                      setDuration(m);
                    }}
                    size={"compact-sm"}
                    variant={"primary-ghost"}
                  >
                    {m}
                  </Button>
                </span>
              );
            })}
            )
          </Label>

          <Input
            type="number"
            id="duration"
            placeholder="Dauer"
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
            }}
            step="5"
            min="05"
            max="120"
          />
        </div>
        <Button
          onClick={() => {
            setMissionDurationAction({
              missionId: missionId,
              duration: Number(duration),
              // awqs: actionsClipboardContent.map((ac) => {
              //   return {
              //     ...ac,
              //     actionLnr: ac.action.lnr,
              //   };
              // }),
            });
          }}
        >
          Dauer ändern
        </Button>

        <div className="text-xs">{mission?.id}</div>
        <DisplayPatientInfo patientId={mission?.patientId || ""} />

        <div>
          {mission?.actionsWithQuantity?.map((awq) => {
            if (!awq) return null;
            if (!awq.action) return null;
            return (
              <div className="relative inline-flex items-center text-xs ">
                <DisplayAction
                  key={awq?.action?.lnr}
                  action={{
                    quantity: awq.quantity,
                    action: awq.action,
                  }}
                />
              </div>
            );
          })}
        </div>
        <div>
          <Button
            disabled={
              countChangedMissions() !== 0 || mission?.connectedMission !== null
            }
            variant={"danger"}
            onClick={() => {
              splitMission({
                missionId,
              });
            }}
          >
            Aufteilen !
          </Button>
        </div>
        {/* <PatientMap
          latitude={mission?.patient?.latitude}
          longitude={mission?.patient?.longitude}
        /> */}
        {mission?.connectedMission && (
          <div>
            <div>Verbundener Einsatz</div>
            <div>
              {mission.connectedMission.member?.shortName}{" "}
              {mission.connectedMission.time}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditMission;
