import React, { useEffect, useRef } from "react";
import inMemoryJwt from "../../Auth0/inMemoryJwt";
import LoginButton from "../../Auth0/LoginButton";
import { useReviewOneDay } from "./useReviewOneDay";

import { Input } from "../../UiComponents/Input";

import dayjs from "dayjs";

import { createId } from "@paralleldrive/cuid2";
import { CheckIcon, InfoIcon, MinusIcon, Trash2 } from "lucide-react";
import { cn } from "../../../helpers/utils";
import { Button } from "../../UiComponents/Button";
import { Switch } from "../../UiComponents/Switch";
import EditManualMobileExecution from "./EditManualMobileExecution";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../UiComponents/Dialog";
import TourSelectReviewOneDayExecution from "./TourSelectReviewOneDayExecution";
import DisplayPatientIdWithLink from "../../Patient/DisplayPatientIdWithLink/DisplayPatientIdWithLink";
import { useMissionSetIsActiveMutation } from "../../../generated/graphql";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../UiComponents/Popover";
import { PopoverRadixTrigger } from "../../UiComponents/PopoverRadix";
import DisplayMissionAnalytic from "../../DisplayMissionAnalytic/DisplayMissionAnalytic";
import { Calendar } from "../../UiComponents/Calendar";
import { actions2Coded } from "../../../helpers/actionLnrCoded";

type Props = {};

export const ReviewOneDayExecution = (props: Props) => {
  const token = inMemoryJwt.getToken();

  if (token === null) {
    return (
      <div>
        Token holen
        <LoginButton />
      </div>
    );
  }

  return (
    <>
      <ReviewOneDayExecution1 />
    </>
  );
};

const ReviewOneDayExecution1 = (props: Props) => {
  const {
    selectedDay,
    setSelectedDay,
    selectedTourId,
    setSelectedTourId,
    allMissions,
    lastUpdated,
    loadData,
    createOrUpdateExecutionMobile,
    deleteExecutionMobile,
    setValidityOneExecutionMobile,
    updatingMissionIds,
    missionActionCompareResultsMap,
    actionsMap,
    missonExecutionResultMap,
  } = useReviewOneDay();

  const [missionSetIsActiveResult, missionSetIsActive] =
    useMissionSetIsActiveMutation();

  useEffect(() => {
    setSelectedDay("2024-09-03");
    setSelectedTourId("S_B_Dümpten");
    loadData();
  }, []);

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleTriggerUpdate = () => {
    //  loadData();
    console.log("triggerUpdate");
  };

  const parseTimeRange = (input: string, index: number): string => {
    // Remove any extra spaces from the input
    const sanitizedInput = input.trim();

    // Regular expression to match various formats
    const timeRangePattern = /(\d{2}):?(\d{2})[-,](\d{2}):?(\d{2})/;

    const match = sanitizedInput.match(timeRangePattern);

    if (!match) {
      // If the input doesn't match any valid pattern, return an empty string or throw an error
      return "";
    }

    // Extract the time components from the regex match
    const startHour = match[1];
    const startMinute = match[2];
    const endHour = match[3];
    const endMinute = match[4];

    if (
      startHour > "23" ||
      endHour > "23" ||
      startMinute > "59" ||
      endMinute > "59"
    ) {
      return "";
    }

    const mission = allMissions[index];
    const day = selectedDay;

    const _codedActions = actions2Coded(
      mission.actionsWithQuantity?.map((a) => {
        return {
          lnr: a?.action?.lnr || "",
          quantity: a?.quantity || 0,
        };
      }) || []
    );

    const inputForCreate = {
      referenceId: mission.id,
      startTS: day + "T" + startHour + ":" + startMinute + ":00",
      endTS: day + "T" + endHour + ":" + endMinute + ":00",
      codedActions: _codedActions || "",
      id: createId(),
      memberId: mission.memberId,
      patientId: mission.patientId,
      referenceType: "mission",
      result: "success",
      remark: "",
    };
    createOrUpdateExecutionMobile(inputForCreate);

    // Return the formatted time range
    return `${startHour}:${startMinute}-${endHour}:${endMinute}`;
  };

  const handleParseInput = (index: number) => {
    const currentInput = inputRefs.current[index];
    if (currentInput) {
      // Parse the input and update the value
      currentInput.value = parseTimeRange(currentInput.value, index);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault(); // Prevent form submission or default behavior
        handleParseInput(index); // Parse the input when Enter is pressed
        // Move focus to the next input
        if (inputRefs.current[index + 1]) {
          inputRefs.current[index + 1]?.focus();
        }
        break;
      case "ArrowUp":
        event.preventDefault(); // Prevent default scrolling behavior
        // Move focus to the previous input
        if (inputRefs.current[index - 1]) {
          inputRefs.current[index - 1]?.focus();
        }
        break;
      case "ArrowDown":
        event.preventDefault(); // Prevent default scrolling behavior
        // Move focus to the next input
        if (inputRefs.current[index + 1]) {
          inputRefs.current[index + 1]?.focus();
        }
        break;
      default:
        break;
    }
  };

  const handleCalendarSelect = (date: Date | undefined) => {
    if (date) setSelectedDay(dayjs(date).format("YYYY-MM-DD"));
  };

  return (
    <div className="h-full w-full bg-white flex flex-col  gap-2 p-2 overflow-y-hidden">
      <div className="flex flex-row items-center gap-2 bg-gray-50 p-2 sticky top-0 justify-center">
        <div className="flex flex-row gap-2 items-center">
          <Button
            onClick={() => {
              if (selectedDay) {
                setSelectedDay(
                  dayjs(selectedDay).subtract(1, "day").format("YYYY-MM-DD")
                );
              }
            }}
          >
            -
          </Button>
          <Popover>
            <PopoverTrigger>
              <Button variant={"neutral-ghost"}>{selectedDay}</Button>
            </PopoverTrigger>
            <PopoverContent>
              <Calendar
                mode="single"
                selected={dayjs(selectedDay).toDate()}
                onSelect={handleCalendarSelect}
                initialFocus
              />
            </PopoverContent>
          </Popover>

          <Button
            onClick={() => {
              if (selectedDay) {
                setSelectedDay(
                  dayjs(selectedDay).add(1, "day").format("YYYY-MM-DD")
                );
              }
            }}
          >
            +
          </Button>
        </div>
        <Dialog>
          <DialogTrigger>
            <Button className="">{selectedTourId}</Button>
          </DialogTrigger>
          <DialogContent className="w-[60%] h-[80%]">
            <div className="h-full overflow-y-hidden">
              <TourSelectReviewOneDayExecution />
            </div>
          </DialogContent>
        </Dialog>

        <Button
          onClick={() => {
            loadData();
          }}
        >
          Laden
        </Button>
      </div>
      <div className="h-full overflow-y-scroll w-full">
        {allMissions.map((m, index) => {
          return (
            <div
              className={cn(
                "flex flex-row border-2 border-gray-300 gap-2 p-2 items-center w-full  ",
                {
                  "border-red-500": !m.isActive,
                }
              )}
            >
              <div className="flex flex-col w-36">
                <div className="text-xs text-gray-500">
                  <DisplayPatientIdWithLink patientId={m.patientId} />
                </div>

                <div>{m.patient?.lastName}</div>
                <div>{m.patient?.firstName}</div>
              </div>
              <div className="flex flex-col">
                <Input
                  disabled={!m.isActive}
                  type="text"
                  ref={(el) => (inputRefs.current[index] = el)}
                  onKeyDown={(event) => handleKeyDown(event, index)}
                  //onBlur={() => handleParseInput(index)}
                />
                <div className="flex flex-row gap-2 text-xs text-gray-500 justify-center items-center">
                  <div>
                    <Popover>
                      <PopoverTrigger>
                        <Button variant={"neutral-ghost"} size="compact-sm">
                          <InfoIcon className="text-blue-500" size={15} />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="text-xs">
                        <DisplayMissionAnalytic missionId={m.id} />
                      </PopoverContent>
                    </Popover>
                  </div>
                  {m?.isActive === true ? (
                    <Button
                      variant={"neutral-ghost"}
                      size="compact-sm"
                      disabled={!m.editAllowed}
                      onClick={() => {
                        missionSetIsActive({
                          id: m.id,
                          isActive: false,
                          updatedAt: m.updatedAt,
                        })
                          .then((res) => {
                            loadData(m.id);
                          })
                          .catch((err) => {
                            console.log("Error", err);
                          });
                      }}
                    >
                      <CheckIcon className="w-4 h-4 text-success" />
                    </Button>
                  ) : (
                    <Button
                      variant={"neutral-ghost"}
                      size="compact-sm"
                      disabled={!m.editAllowed}
                      onClick={() => {
                        missionSetIsActive({
                          id: m.id,
                          isActive: true,
                          updatedAt: m.updatedAt,
                        }).then((res) => {
                          loadData(m.id);
                        });
                      }}
                    >
                      <MinusIcon className="w-4 h-4 text-red-500" />
                    </Button>
                  )}
                  <div>{dayjs(Number(m.startTS)).format("HH:mm")}</div>
                  <div>-</div>
                  <div>{dayjs(Number(m.endTS)).format("HH:mm")}</div>
                </div>
              </div>
              {/* {updatingMissionIds.includes(m.id) && <div>Updating...</div>} */}
              <div
                className={cn("w-full ", {
                  "disabled bg-teal-200": updatingMissionIds.includes(m.id),
                })}
              >
                {m.executionMobiles.map((e) => {
                  if (e.result !== "success") {
                    return null;
                  }

                  const _missionActionExecutions =
                    missonExecutionResultMap?.get(e.id);
                  if (e.source === "mobile-app") {
                    return (
                      <div className="flex flex-row gap-2 items-center w-full">
                        <Switch
                          checked={!e.isInvalid}
                          onClick={() => {
                            setValidityOneExecutionMobile({
                              id: e.id,
                              missionId: m.id,
                              valid: !e.isInvalid,
                            });
                          }}
                          onChange={() => {
                            console.log("switch", e.id);
                          }}
                        />

                        <div
                          key={e.id}
                          className={cn("flex flex-row gap-2 items-center", {
                            "text-gray-300": e.isInvalid,
                          })}
                        >
                          <div>{dayjs(e.startTS_iso).format("HH:mm")}</div>
                          <div>-</div>
                          <div>{dayjs(e.endTS_iso).format("HH:mm")}</div>
                          <div>
                            {_missionActionExecutions && (
                              <div className="bg-white p-1 flex flex-row gap-2 text-xs">
                                {_missionActionExecutions.map((ae) => {
                                  return (
                                    <div
                                      key={ae.actionLnr}
                                      className={cn(
                                        "flex flex-row gap-2 items-center"
                                      )}
                                    >
                                      <div
                                        className={cn("p-1", {
                                          "bg-red-100":
                                            ae.executionStatus ===
                                            "notExecuted",
                                          "bg-green-100":
                                            ae.executionStatus === "executed",
                                        })}
                                      >
                                        {ae.action?.action?.leistungskomplex}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                          <div>{e.remark}</div>
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div
                      key={e.id}
                      className="flex flex-row gap-2 w-full items-center"
                    >
                      <div>
                        <Trash2
                          size={18}
                          onClick={() =>
                            deleteExecutionMobile({
                              id: e.id,
                              missionId: m.id,
                            })
                          }
                        />
                      </div>
                      <div>{e.sourceDisplay} </div>
                      <div>{dayjs(e.startTS_iso).format("HH:mm")}</div>
                      <div>-</div>
                      <div>{dayjs(e.endTS_iso).format("HH:mm")}</div>
                      <EditManualMobileExecution
                        execution={e}
                        mission={m}
                        remark={e.remark}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReviewOneDayExecution;
